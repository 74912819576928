import React from "react";
import icon1 from "../../../assets/discover_icon1.png";
import icon2 from "../../../assets/discover_icon2.png";
import icon3 from "../../../assets/discover_icon3.png";

const Discover = () => {
  return (
    <div className="bg_discover p-lg-5 p-2">
      <div className="container py-5 position-relative">
        <div className="text-center mb-5">
          <h2 className="fw-bold">Discover Our Services</h2>
          <p className="text-muted">
            Lorem Ipsum is simply dummy text of the printing <br /> and
            typesetting industry.
          </p>
        </div>

        <div className="row justify-content-center">
          {/* Service Cards */}
          {[
            { title: "Sell Property", image: icon1 },
            { title: "Buy Property", image: icon2 },
            { title: "Sell Property", image: icon3 },
          ].map((service, index) => (
            <div className="col-md-4" key={index}>
              <div className="card_b border-0 shadow-sm text-center p-4 mb-3">
                <div
                  className="mx-auto mt-4 mb-4 d-flex justify-content-center align-items-center"
                  style={{
                    backgroundColor: "#D4AF37",
                    width: "90px",
                    height: "90px",
                    borderRadius: "50%",
                  }}
                >
                  <img src={service.image} alt="" />
                  {/* <i className={`bi ${service.icon}`} style={{ fontSize: "30px", color: "#343a40" }}></i> */}
                </div>
                <h5 className="fw-bold">{service.title}</h5>
                <p className="text-muted">
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry.
                </p>
              </div>
            </div>
          ))}
        </div>

        {/* Floating Buttons on the Right */}
        {/* <div className="position-fixed d-flex flex-column" style={{ top: "20%", right: "10px" }}>
        <button
          className="btn btn-dark mb-3 d-flex justify-content-center align-items-center"
          style={{ width: "50px", height: "50px", borderRadius: "50%" }}
        >
          <i className="bi bi-person-fill text-warning"></i>
        </button>
        <button
          className="btn btn-dark d-flex justify-content-center align-items-center"
          style={{ width: "50px", height: "50px", borderRadius: "50%" }}
        >
          <i className="bi bi-calendar-event-fill text-warning"></i>
        </button>
      </div> */}
      </div>
    </div>
  );
};

export default Discover;
