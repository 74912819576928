import React from "react";
import {
  StackedCarousel,
  ResponsiveContainer,
} from "react-stacked-center-carousel";
import { Button, Container, Row, Col } from "react-bootstrap";
import { MdArrowForwardIos, MdArrowBackIos } from "react-icons/md";
import image1 from "../../../assets/pj1.png";

export const data = [
  {
    cover: image1,
    title: "Interstellar",
  },
  {
    cover: image1,
    title: "Inception",
  },
  {
    cover: image1,
    title: "Blade Runner 2049",
  },
  // Add more data objects here
];

export default function ResponsiveCarousel(props) {
  const ref = React.useRef();

  const handlePrevClick = () => {
    ref.current?.goBack();
  };

  const handleNextClick = () => {
    ref.current?.goNext(6);
  };

  return (
    <div style={{ width: "100%", position: "relative" }}>
      <ResponsiveContainer
        carouselRef={ref}
        render={(parentWidth, carouselRef) => {
          let currentVisibleSlide = 5;
          if (parentWidth <= 1440) currentVisibleSlide = 3;
          if (parentWidth <= 1080) currentVisibleSlide = 1;

          return (
            <StackedCarousel
              ref={carouselRef}
              slideComponent={Card}
              slideWidth={parentWidth < 800 ? parentWidth - 40 : 750}
              carouselWidth={parentWidth}
              data={data}
              currentVisibleSlide={currentVisibleSlide}
              maxVisibleSlide={5}
              useGrabCursor
            />
          );
        }}
      />
      <Container fluid>
        <Row className="align-items-center">
          <Col xs={2}>
            <Button
              // variant="primary"
              className="position-absolute top-50 start-0 translate-middle-y btn-arrow left-arrow"
              onClick={handlePrevClick}
            >
              <MdArrowBackIos />
            </Button>
          </Col>
          <Col xs={2}>
            <Button
              // variant="primary"
              className="position-absolute top-50 end-0 translate-middle-y btn-arrow right-arrow"
              onClick={handleNextClick}
            >
              <MdArrowForwardIos />
            </Button>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export const Card = React.memo(function (props) {
  const { data, dataIndex } = props;
  const { cover } = data[dataIndex];
  return (
    <div
      style={{
        width: "100%",
        height: 300,
        userSelect: "none",
      }}
      className="my-slide-component"
    >
      <img
        style={{
          height: "100%",
          width: "100%",
          objectFit: "cover",
          borderRadius: 0,
        }}
        draggable={false}
        src={cover}
        alt={`Slide ${dataIndex}`}
      />
    </div>
  );
});
