import React, { useEffect, useState } from "react";
import InquiryForm from "./InquiryForm";
import bg1 from "../../../assets/bg_hero.png";
import bg2 from "../../../assets/bg_hero.png"; // Replace with actual paths
import bg3 from "../../../assets/bg_hero.png";

const HeroSection = () => {
  const [activeBgIndex, setActiveBgIndex] = useState(0);

  // Array of background images
  const backgrounds = [bg1, bg2, bg3];

  // Update background index at intervals
  useEffect(() => {
    const interval = setInterval(() => {
      setActiveBgIndex((prevIndex) => (prevIndex + 1) % backgrounds.length);
    }, 3000); // Change every 3 seconds

    return () => clearInterval(interval);
  }, [backgrounds.length]);
  return (
    <div
      className="p-lg-5 p-2 hero-section"
      style={{
        backgroundImage: `url(${backgrounds[activeBgIndex]})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        transition: "background-image 1s ease-in-out",
      }}
    >
      <div className="container">
        <div className="row justify-content-center align-items-center">
          <div className="col-lg-7 col-12 mb-3">
            <h1 className="text-white main_text mt-lg-0 mt-3">
              Find Your Dream <br className="d-lg-block d-none" />
              Home Today
            </h1>
            <p className="text-white">
              Are you ready to start the search for your dream home? Look no
              further
              <br /> than Uniglobe marketing
            </p>
            <button className="btn btn-main d-flex justify-content-start">
              Contact Us
            </button>
          </div>
          <div className="col-lg-5 col-12">
            <InquiryForm />
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeroSection;
