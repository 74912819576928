import React from "react";
import Slider from "react-slick";

const CustomSlider = ({ data, settings }) => {
  return (
    <div className="mx-auto p-3">
      <Slider {...settings} className="m-2">
        {data.map((item, index) => (
          <div
            key={index}
            className="bg-color rounded-lg items-center text-center p-3"
          >
            <div className="rounded-full margin-auto">
              <img
                src={item.image}
                alt={item.name}
                width={80}
                height={80}
                className="object-cover margin-auto d-flex"
              />
            </div>
            <h3 className="text-lg font-semibold">{item.name}</h3>
            <p className="text-sm text-gray-500 mb-0">{item.city}</p>
            <p className="text-gray-700 text-sm p-2 mb-0">{item.text}</p>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default CustomSlider;
