import React, { Suspense } from "react";
import Navbar from "../common/navbar/Navbar";
import HeroSection from "../components/unihomes/HeroSection";

// Lazy load other components
const ProjectOverview = React.lazy(() =>
  import("../components/unihomes/ProjectOverview")
);
const LocationHighlight = React.lazy(() =>
  import("../components/unihomes/LocationHighlight")
);
const Facilities = React.lazy(() =>
  import("../components/unihomes/Facilities")
);
const PaymentPlan = React.lazy(() =>
  import("../components/unihomes/PaymentPlan")
);
const Gallery = React.lazy(() => import("../components/unihomes/Gallery"));
// const Video = React.lazy(() => import("../components/unihomes/Video"));  // Uncomment if you need it
const Footer = React.lazy(() => import("../common/footer/Footer"));

const UniHomes = () => {
  return (
    <>
      <Navbar />
      <HeroSection />

      {/* Lazy-loaded components wrapped in Suspense */}
      <Suspense fallback={<div>Loading...</div>}>
        <ProjectOverview />
        <LocationHighlight />
        <Facilities />
        <PaymentPlan />
        <Gallery />
        {/* <Suspense fallback={<div>Loading...</div>}>
        <Video />
      </Suspense> */}
        <Footer />
      </Suspense>
    </>
  );
};

export default UniHomes;
