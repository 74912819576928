import React from "react";
import pj1 from "../../../assets/pj1.png"; // Import the image properly

const ProjectCard = ({ title, image }) => {
  return (
    <div className="col-lg-6 col-md-6 col-sm-12 mb-4">
      <div
        className="card border-0 position-relative overflow-hidden"
        style={{
          borderRadius: "8px",
        }}
      >
        <div
          className="bg-image"
          style={{
            backgroundImage: `url(${image})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            height: "380px",
          }}
        >
          <div
            className="card-overlay position-absolute w-100 bottom-0 d-flex align-items-end"
            style={{
              background:
                "linear-gradient(0deg, rgb(0 0 0 / 80%), rgb(0 0 0 / 10%))",
              height: "70px",
            }}
          >
            <h3 className="text-card ms-3 mb-3 px-3">{title}</h3>
          </div>
        </div>
      </div>
    </div>
  );
};

const BrowseProjects = () => {
  const projects = [
    { title: "University Homes", image: pj1 },
    { title: "Uniglobe Complex", image: pj1 },
  ];

  return (
    <section className="browse-projects py-5">
      <div className="container">
        {/* Section Title */}
        <div className="text-center mb-5">
          <h2 className="fw-bold">Browse Our Projects</h2>
          <p className="text-muted">
            Lorem Ipsum is simply dummy text of the printing <br /> and
            typesetting industry.
          </p>
        </div>
        {/* Cards */}
        <div className="row">
          {projects.map((project, index) => (
            <ProjectCard
              key={index}
              title={project.title}
              image={project.image}
            />
          ))}
        </div>
      </div>
    </section>
  );
};

export default BrowseProjects;
