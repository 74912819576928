import React, { useEffect, useState } from "react";
import InquiryForm from "./InquiryForm";

import bg1 from "../../assets/unihomes/1.jpg";
import bg2 from "../../assets/unihomes/2.jpg";
import bg3 from "../../assets/unihomes/3.jpg";
import Booking from "./booking/Booking";
import CustomModal from "../../common/modal/CustomModal";

const HeroSection = () => {
  const [activeBgIndex, setActiveBgIndex] = useState(0);
  const [booking, setBooking] = useState(false);
  // Array of background images
  const backgrounds = [bg1, bg2, bg3];

  // Update background index at intervals
  useEffect(() => {
    const interval = setInterval(() => {
      setActiveBgIndex((prevIndex) => (prevIndex + 1) % backgrounds.length);
    }, 3000);
    return () => clearInterval(interval);
  }, [backgrounds.length]);
  return (
    <>
      <div
        className="hero-section"
        id="inquiry"
        style={{
          backgroundImage: `url(${backgrounds[activeBgIndex]})`,
        }}
      >
        <div className="container">
          <div className="row justify-content-center align-items-center">
            <div className="col-lg-7 col-12 mb-3 text-white">
              <h1 className="heading-uni-main">
                Find Your Dream <br /> Home Today
              </h1>
              <p className="fs-4 mb-0">
                Are you ready to start the search for your dream home?
              </p>
              <p className="fs-4">Look no further than Uniglobe marketing</p>
              <button
                className="btn btn-uni-main px-5"
                onClick={() => setBooking(true)}
              >
                Book Your Unit
              </button>
            </div>
            <div className="col-lg-5 col-12">
              <InquiryForm />
            </div>
          </div>
        </div>
      </div>
      <CustomModal
        title="Property Booking Form (Through VRC)"
        isOpen={booking}
        onClose={() => setBooking(false)}
      >
        <Booking setBooking={setBooking} />
      </CustomModal>
    </>
  );
};

export default HeroSection;
