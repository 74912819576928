import React from "react";
import HeroSection from "../components/uniglobe/landingpage/HeroSection";
import BrowseProjects from "../components/uniglobe/landingpage/Projects";
import WhyChoose from "../components/uniglobe/landingpage/WhyChoose";
import Discover from "../components/uniglobe/landingpage/Discover";
import StatsSection from "../components/uniglobe/landingpage/StatsSection";
import Carousel from "../components/uniglobe/landingpage/Carasoul";
import Testimonials from "../components/uniglobe/landingpage/Testimonials";
import Navbar from "../common/navbar/Navbar";
import Footer from "../common/footer/Footer";

const Home = () => {
  return (
    <div>
      <Navbar />
      <HeroSection />
      <BrowseProjects />
      <WhyChoose />
      <Discover />
      <StatsSection />
      <div className="container mb-3 py-5">
        <div className="row">
          <div className="text-center mb-5">
            <h2 className="fw-bold">Explore the Society</h2>
            <p className="text-muted">
              Lorem Ipsum is simply dummy text of the printing <br /> and
              typesetting industry.
            </p>
          </div>
          <Carousel />
        </div>
        <Testimonials />
      </div>
      <Footer />
    </div>
  );
};

export default Home;
