import React from 'react';

function StatsSection() {
  return (
    <div className="text-white py-4 mb-3" style={{backgroundColor: '#303030'}}>
      <div className="container">
        <div className="row text-center">
          <div className="col-md-4 golden-border p-3">
            <h2 className="fw-bold">20+</h2>
            <p className='mb-0'>Years in the industry</p>
          </div>
          <div className="col-md-4 golden-border p-3">
            <h2 className="fw-bold">500+</h2>
            <p className='mb-0'>Customer satisfaction</p>
          </div>
          <div className="col-md-4 p-3">
            <h2 className="fw-bold">500+</h2>
            <p className='mb-0'>Properties sold</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default StatsSection;
