import React from "react";
import icon from "../../../assets/choose_icon.png";
import choosemain from "../../../assets/choose_main.png";
const WhyChoose = () => {
  return (
    <div style={{ backgroundColor: "#2c2c2c" }}>
      <div className="container py-5">
        <div className="row p-lg-5 p-2">
          {/* Left Section */}
          <div className="col-md-6">
            <div className="text-light mb-5">
              <h2 className="fw-bold color">Why Choose UniGlobe Marketing?</h2>
              <p>
                Lorem Ipsum is simply dummy text of the printing{" "}
                <br className="d-lg-block d-none" /> and typesetting industry.
              </p>
            </div>
            <div className="mb-4 d-flex align-items-start">
              <div className="me-3">
                <div className="bg-color2 text-dark rounded-circle p-3">
                  <img src={icon} alt="" />
                </div>
              </div>
              <div>
                <h5 className="color">Excellent Communication</h5>
                <p className="text-light">
                  Lorem Ipsum is simply dummy text of the printing{" "}
                  <br className="d-lg-block d-none" /> and typesetting industry.
                </p>
              </div>
            </div>
            <div className="mb-4 d-flex align-items-start">
              <div className="me-3">
                <div className="bg-color2 text-dark rounded-circle p-3">
                  <img src={icon} alt="" />
                </div>
              </div>
              <div>
                <h5 className="color">Excellent Communication</h5>
                <p className="text-light">
                  Lorem Ipsum is simply dummy text of the printing{" "}
                  <br className="d-lg-block d-none" /> and typesetting industry.
                </p>
              </div>
            </div>
            <div className="mb-4 d-flex align-items-start">
              <div className="me-3">
                <div className="bg-color2 text-dark rounded-circle p-3">
                  <img src={icon} alt="" />
                </div>
              </div>
              <div>
                <h5 className="color">Excellent Communication</h5>
                <p className="text-light">
                  Lorem Ipsum is simply dummy text of the printing{" "}
                  <br className="d-lg-block d-none" /> and typesetting industry.
                </p>
              </div>
            </div>
            <button className="btn btn-main d-flex justify-content-start">
              Contact Us
            </button>
          </div>

          {/* Right Section */}
          <div className="col-md-6 d-lg-block d-none">
            <img src={choosemain} alt="" />
            {/* <div className="row g-3">
                            <div className="col-6">
                                <div className="border border-warning rounded overflow-hidden">
                                    <img
                                        src="https://via.placeholder.com/150"
                                        alt="Project"
                                        className="img-fluid"
                                    />
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="border border-warning rounded overflow-hidden">
                                    <img
                                        src="https://via.placeholder.com/150"
                                        alt="Project"
                                        className="img-fluid"
                                    />
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="border border-warning rounded overflow-hidden">
                                    <img
                                        src="https://via.placeholder.com/150"
                                        alt="Project"
                                        className="img-fluid"
                                    />
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="border border-warning rounded overflow-hidden">
                                    <img
                                        src="https://via.placeholder.com/150"
                                        alt="Project"
                                        className="img-fluid"
                                    />
                                </div>
                            </div>
                        </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default WhyChoose;
