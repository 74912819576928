import React from "react";
import CustomSlider from "../../../common/CustomSlider";
import userimg from "../../../assets/userimg.png";

const Testimonials = () => {
  const testimonialData = [
    {
      name: "Client Name 1",
      city: "Client City 1",
      image: userimg,
      text: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
    },
    {
      name: "Client Name 2",
      city: "Client City 2",
      image: userimg,
      text: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
    },
    {
      name: "Client Name 3",
      city: "Client City 3",
      image: userimg,
      text: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
    },
  ];

  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <div className="container py-5 position-relative">
      <div className="text-center mb-2 pb-1">
        <h2 className="fw-bold">Hear What They Say</h2>
        <p className="text-muted">
          Lorem Ipsum is simply dummy text of the printing <br /> and
          typesetting industry.
        </p>
      </div>
      <CustomSlider data={testimonialData} settings={sliderSettings} />
    </div>
  );
};

export default Testimonials;
