import React from "react";

const InquiryForm = () => {
  return (
    <div
      className="">
      <div
        className="card shadow p-4"
      >
        <h2 className="fw-bold">Real Estate Inquiry Form</h2>
        <p className="text-muted">
          Please provide the following information to help us better understand
          your real estate needs.
        </p>

        {/* Form starts here */}
        <form>
          <h5 className="fw-bold">Your Information</h5>
          {/* Name Input */}
          <div className="mb-1">
            <label htmlFor="name" className="form-label">
              Your Name
            </label>
            <input
              type="text"
              className="form-control"
              id="name"
            //   placeholder="Enter your name"
            />
          </div>

          {/* Email Input */}
          <div className="mb-1">
            <label htmlFor="email" className="form-label">
              Your Email
            </label>
            <input
              type="email"
              className="form-control"
              id="email"
            //   placeholder="Enter your email"
            />
          </div>

          <h5 className="fw-bold mt-2 mb-0">Your Preference</h5>
          {/* Property Type Input */}
          <div className="mb-1">
            <label htmlFor="propertyType" className="form-label">
              Type of property you are interested in
            </label>
            <input
              type="text"
              className="form-control"
              id="propertyType"
            //   placeholder="E.g., Residential, Commercial"
            />
          </div>

          {/* Location Input */}
          <div className="mb-1">
            <label htmlFor="location" className="form-label">
              What is your preferred location?
            </label>
            <input
              type="text"
              className="form-control"
              id="location"
            //   placeholder="Enter preferred location"
            />
          </div>

          {/* Budget Input */}
          <div className="mb-1">
            <label htmlFor="budget" className="form-label">
              What is your budget?
            </label>
            <input
              type="text"
              className="form-control"
              id="budget"
            //   placeholder="Enter your budget"
            />
          </div>

          {/* Submit Button */}
          <div className="d-grid mt-2">
            <button type="submit" className="btn btn-form btn-lg">
              Get Inquiry
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default InquiryForm;
