import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import UniHomes from "./pages/UniHomes";
import { Toaster } from "react-hot-toast";

import "./App.css";

function App() {

  return (
    <>
    <Toaster position="bottom-center" reverseOrder={false} />
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/universityhomes" element={<UniHomes />} />
      </Routes>
    </BrowserRouter>
    </>
  );
}

export default App;
