import React from "react";
import { FaFacebook, FaTwitter, FaInstagram } from "react-icons/fa";

import img from "../../assets/unihomes/uniglobe.svg";
import root from "../../assets/unihomes/svg/rootsdeveloper.svg";

const Footer = () => {
  return (
    <div className="footer">
      <div className="container py-5 border-bottom border-white">
        <div className="row g-5">
          <div className="col-lg-3 col-md-6 col-12">
            <img
              className="w-100"
              src={require("../../assets/unihomes/university.png")}
              alt=""
            />
          </div>
          {/* <div className="col-lg-3 col-md-6 col-12">
            <h2>Head Office</h2>
            <ul>
              <li>
                Office # 9 Union Complex Ferozepur Road Opposite Metro
                Station # 25 Lahore.
              </li>
            </ul>
          </div> */}

          <div className="col-lg-3 col-md-6 col-12">
            <h3>Contact Info</h3>
            <ul>
              {/* <li>051 543 8866</li> */}
              {/* <li>www.rootsbuilders.com</li> */}
              <li>inquiry@uniglobemarketing.com</li>
              <li>
                Office # 9 Union Complex Ferozepur Road Opposite Metro
                Station # 25 Lahore.
              </li>
            </ul>
          </div>

          <div className="col-lg-3 col-md-6 col-12 ps-5">
            <h3>Marketed By</h3>
            <img width={200} src={img} alt="" />
          </div>

          <div className="col-lg-3 col-md-6 col-12 d-flex">
            <img className="mx-auto" width={100} src={root} alt="" />
          </div>
        </div>
      </div>
      <div className="container d-flex justify-content-between pt-4 pb-3">
        <p className="text-center text-white">
          Designed & Developed by Virtual tech @copyright 2024
        </p>
        <div className="d-flex flex-wrap gap-3">
          <a
            href="https://www.facebook.com/share/15NrdiuKew/"
            target="_blanl"
            rel="noopener norefer"
            aria-label="Facebook"
          >
            <FaFacebook color="#fff" size={25} />
          </a>
          <a href="#" aria-label="Twitter">
            <FaTwitter color="#fff" size={25} />
          </a>
          <a href="#" aria-label="Instagram">
            <FaInstagram color="#fff" size={25} />
          </a>
        </div>
      </div>
    </div>
  );
};

export default Footer;
