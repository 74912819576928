import React, { useState } from "react";
import { toast } from "react-hot-toast";

const InquiryForm = () => {
  // State to store form values
  const [formData, setFormData] = useState({
    fullName: "",
    mobileNumber: "",
    email: "",
    interestedArea: "",
    preferredArea: "",
  });

  // Handler to update form values
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  // Form submit handler
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const baseURL = process.env.REACT_APP_BASE_URL;
      const response = await fetch(`${baseURL}/api/inquiry/`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData),
        }
      );
      // const response = await fetch(
      //   "https://be.uniglobemarketing.com/api/inquiry/",
      //   {
      //     method: "POST",
      //     headers: {
      //       "Content-Type": "application/json",
      //     },
      //     body: JSON.stringify(formData),
      //   }
      // );

      console.log(response);
      if (!response.ok) {
        throw new Error("Failed to submit form");
      }
      toast.success("Form submitted successfully!");
    } catch (error) {
      toast.error(error.message);
    }
  };

  return (
    <div className="container py-4">
      <div className="card shadow p-4">
        <h2 className="fw-bold mb-1">Real Estate Inquiry Form</h2>
        <p className="text-muted">
          Please provide the following information to help us better understand
          your real estate needs.
        </p>

        {/* Form starts here */}
        <form onSubmit={handleSubmit}>
          <h5 className="fw-bold">Your Information</h5>

          {/* Full Name Input */}
          <div className="mb-1">
            <label htmlFor="fullName" className="form-label">
              Full Name
            </label>
            <input
              type="text"
              className="form-control"
              id="fullName"
              name="fullName"
              value={formData.fullName}
              onChange={handleChange}
              required
            />
          </div>

          {/* Mobile Number Input */}
          <div className="mb-1">
            <label htmlFor="mobileNumber" className="form-label">
              Mobile Number
            </label>
            <input
              type="text"
              className="form-control"
              id="mobileNumber"
              name="mobileNumber"
              value={formData.mobileNumber}
              onChange={handleChange}
              required
            />
          </div>

          {/* Email Input */}
          <div className="mb-1">
            <label htmlFor="email" className="form-label">
              Email
            </label>
            <input
              type="email"
              className="form-control"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
            />
          </div>

          <h5 className="fw-bold mt-3">Your Preferences</h5>

          {/* Interested Area */}
          <div className="mb-2">
            <label className="form-label d-block fw-bold">
              Interested Area
            </label>
            {["3 Marla", "4 Marla", "5 Marla", "10 Marla"].map((area) => (
              <div className="form-check form-check-inline" key={area}>
                <input
                  className="form-check-input"
                  type="radio"
                  id={area}
                  name="interestedArea"
                  value={area}
                  onChange={handleChange}
                  required
                />
                <label className="form-check-label" htmlFor={area}>
                  {area}
                </label>
              </div>
            ))}
          </div>

          {/* Preferred Area */}
          <label className="form-label d-block fw-bold">Preferred Area</label>
          <div className="d-flex gap-3 mb-3">
            {["Corner Plot", "Facing Park", "General"].map((preference) => (
              <div className="form-check" key={preference}>
                <input
                  className="form-check-input"
                  type="radio"
                  id={preference}
                  name="preferredArea"
                  value={preference}
                  onChange={handleChange}
                  required
                />
                <label className="form-check-label" htmlFor={preference}>
                  {preference}
                </label>
              </div>
            ))}
          </div>

          {/* Submit Button */}
          <div className="d-grid mt-4">
            <button type="submit" className="btn btn-uni-main btn-lg">
              Get Inquiry
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default InquiryForm;
